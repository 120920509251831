
import { computed, defineComponent, getCurrentInstance, reactive, ref } from "vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import { IonSlides, IonSlide } from "@ionic/vue";
import useVuelidate from "@vuelidate/core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import UserService from "@/services/UserService";
import MoreForm from "@/views/Onboarding/Forms/MoreForm.vue";
import i18n from "@/i18n";

export default defineComponent({
  name: "MoreInformation",
  components: { MoreForm, GuestLayout, IonSlides, IonSlide },
  setup: function () {
    const currentIndex = ref<number>(0);
    const slider = ref(null);
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const $toasted = getCurrentInstance()?.appContext.config.globalProperties.$toasted;
    const $t = i18n.global;

    const showBackBtn = computed(() => currentIndex.value > 0);

    const next = (value: number) => {
      currentIndex.value = value;
      //@ts-ignore
      slider.value.$el.slideTo(value);
    };

    const form1 = reactive({
      freeTextQuestions: null,
    });

    const form2 = reactive({
      daysOfWeekForContact: [],
      startTimeForContact: null,
      endTimeForContact: null,
      phoneNumberForContact: null,
    });

    const form2Validation = useVuelidate();

    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      noSwiping: true,
      noSwipingClass: "swiper-no-swiping",
      autoHeight: true,
    };

    const onSubmit = async () => {
      const drivingSchool = UserService.getDrivingSchoolId();

      if (!drivingSchool) return;

      const data = Object.assign(
        {
          drivingSchoolId: drivingSchool,
          requestContract: false,
        },
        form1,
        form2
      );

      await registerAction({
        resource: "submit-registration",
        hideSuccessMessage: true,
        data: data,
      });

      console.log(registerSuccess);

      if (registerSuccess.value) {
        //@ts-ignore
        $toasted?.success(i18n.global.t("messages.request_sent_successfully").toString(), { duration: 3000 });
        await router.push({ name: "BasicDashboard" });
      }
    };

    const registerAction = async (options: any) => {
      await store.dispatch("submit-registration/create", options);
    };

    const registerLoading = computed(() => store.getters["submit-registration/getIsLoading"]);
    const registerSuccess = computed(() => store.getters["submit-registration/getSuccess"]);

    const onClickBackBtn = () => {
      currentIndex.value--;
      next(currentIndex.value);
    };

    return {
      showBackBtn,
      next,
      form1,
      form2,
      form2Validation,
      slideOpts,
      onSubmit,
      onClickBackBtn,
      slider,
      registerLoading,
    };
  },
});
