
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

@Options({})
export default class SelectableDaysOfWeek extends Vue {
  public name = "SelectableDaysOfWeek";

  @Prop({ type: Array, default: () => [] })
  public modelValue!: Array<number>;

  public val: any = [];

  protected get days() {
    return [
      this.$t("short_days.monday"),
      this.$t("short_days.tuesday"),
      this.$t("short_days.wednesday"),
      this.$t("short_days.thursday"),
      this.$t("short_days.friday"),
      this.$t("short_days.saturday"),
      this.$t("short_days.sunday"),
    ];
  }

  @Watch("value", { deep: true, immediate: true })
  public onChangeValue() {
    this.val = this.modelValue;
  }

  public get getValue() {
    return this.val;
  }

  public set getValue(value: any) {
    this.val.push(value);
    this.$emit("update:modelValue", value);
  }

  protected onSelect(day: number): void {
    this.updateSelection(day);
  }

  protected updateSelection(resource: any) {
    if (this.getValue.indexOf(resource) < 0) return this.getValue.push(resource);
    const index = this.getValue.indexOf(resource);
    if (index > -1) return this.getValue.splice(index, 1);
  }

  protected isSelected(day: number): boolean {
    return this.getValue.indexOf(day) > -1;
  }
}
