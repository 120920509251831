
import { defineComponent } from "vue";
import SelectableDaysOfWeek from "@/components/SelectableDaysOfWeek.vue";
import FscDatepicker from "@/components/FscDatepicker.vue";

export default defineComponent({
  name: "MoreForm",
  components: { FscDatepicker, SelectableDaysOfWeek },
  props: {
    v: {},
    data: {
      required: true,
    },
    submitBtnText: {
      default: () => "Ausbildungsvertrag unverbindlich anfragen!",
    },
    loading: {
      default: () => false,
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const onSubmit = () => {
      emit("submit");
    };
    return {
      onSubmit,
    };
  },
});
